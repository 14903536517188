<script lang="ts" setup>
const RESEND_EMAIL_TIMEOUT_MILLISECONDS = 599_000;
const { t } = useT();
const { open } = useAppModals();
const { emailVerificationTimerCookie } = useEmailVerificationTimerCookie();
const { duration, durationLeft, reset } = useCountdown({
	timestamp: Number(emailVerificationTimerCookie.value) + RESEND_EMAIL_TIMEOUT_MILLISECONDS,
	formatToken: "mm[ : ]ss"
});

const handleRequestEmailConfirm = () => {
	if (duration.value > 0) {
		return;
	}

	dispatchGAEvent({
		event: "verification",
		location: "header",
		step: "info",
		type: "email"
	});
	open("LazyOModalEmailConfirm");
};

watch(emailVerificationTimerCookie, (value) => {
	if (value) {
		reset(Number(emailVerificationTimerCookie.value) + RESEND_EMAIL_TIMEOUT_MILLISECONDS);
	}
});
</script>
<template>
	<div class="email-notify text-coro" :class="{ 'is-sent': duration > 0 }" @click="handleRequestEmailConfirm">
		<ASvg name="header/warning" class="icon" />
		<AText as="div" variant="toledo" :modifiers="['inheritSize']">
			<i18n-t keypath="An email was sent to you. Click the {key1} in your inbox to complete the sign-up! {key2}">
				<template #key1>
					{{ t("verification link") }}
				</template>
				<template #key2>
					<AText v-if="duration > 0" class="resend" :modifiers="['medium', 'inheritSize']">
						{{ t("Resend") }} ({{ durationLeft }})
					</AText>
					<AText v-else class="resend" :modifiers="['underline', 'inheritSize', 'medium']">{{
						t("Click to resend!")
					}}</AText>
				</template>
			</i18n-t>
		</AText>
	</div>
</template>
<style lang="scss" scoped>
.email-notify {
	padding: gutter(2);
	gap: gutter(0.625);
	display: flex;
	justify-content: center;
	background-color: var(--caracas);
	color: var(--cheng);
	z-index: 3;
	line-height: 18px;

	&:not(.is-sent) {
		&:hover {
			cursor: pointer;
			opacity: 0.85;
		}
	}

	.icon {
		font-size: 16px;
		line-height: 1;
		position: relative;
		top: -1px;
	}

	@media (max-width: 1125px) {
		font-size: 14px;
		line-height: 16px;
	}

	@include media-breakpoint-down(lg) {
		padding: gutter(1.5);

		.icon {
			display: none;
		}
	}
}
</style>
